import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Divider,
    Fab,
    IconButton,
    InputBase,
    List as MaterialList,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Collapse,
    makeStyles,
    Paper
} from "@material-ui/core";
import {Add, Done, Edit, LocalShipping, NotInterested, PersonAdd, QueryBuilder, Search, ExpandLess, ExpandMore} from "@material-ui/icons";
import {AppContext, AuthContext} from '../../context';
import {useDataAccess} from '../custom_hooks';
import {ConfirmDialog} from "../dialog";
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    list: {
        marginBottom: theme.spacing(5),
    },
    listPadded: {
        marginLeft: theme.spacing(2),
    },
    addFab: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    paper: {
        maxWidth: 500,
        margin: 'auto',
        marginTop: theme.spacing(1),
    },
    input: {
        width: '80%',
        color: theme.palette.text.customPrimary,
    },
    icon: {
        color: theme.palette.primary.main,
        '&:disabled': {
            color: theme.palette.text.secondary,
        }
    },
}));

const List = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const { fetch } = useDataAccess();
    const [openDialog, setOpenDialog] = useState(false);
    const [openSuppliers, setOpenSuppliers] = useState(true);
    const [openResellers, setOpenResellers] = useState(true);
    const [openCustomers, setOpenCustomers] = useState(true);
    const [organizations, setOrganizations] = useState([]);
    const [ownSuppliersId, setOwnSuppliersId] = useState([]);
    const [ownResellersId, setOwnResellersId] = useState([]);
    const [ownCustomersId, setOwnCustomersId] = useState([]);
    const [requestedOrganizationsId, setRequestedOrganizationsId] = useState([]);
    const [requesterOrganizationsId, setRequesterOrganizationsId] = useState([]);
    const [filteredOrganizations, setFilteredOrganizations] = useState([]);
    const [filteredSuppliers, setFilteredSuppliers] = useState([]);
    const [filteredResellers, setFilteredResellers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [organizationId, setOrganizationId] = useState(0);

    useEffect(() => {
        setOrganizations([]);
        if(!props.match.params.type) {
            setPageInfos('organizations', true);
            if(
                authState.user &&
                !authState.user.roles.includes('ROLE_SUPERADMIN')
            ) {
                addRefreshResourceAppState('get-all-organizations');
            } else {
                addRefreshResourceAppState('organizations');
            }
            addRefreshResourceAppState('organization_requests');
        } else {
            setPageInfos(props.match.params.type, true);
            if(
                authState.user &&
                !authState.user.roles.includes('ROLE_SUPERADMIN')
            ) {
                addRefreshResourceAppState(props.match.params.type);
            } else {
                addRefreshResourceAppState('organizations');
            }
        }
        setOrganizationsFromCache();
        // eslint-disable-next-line
    }, [props.match.params.type]);

    useEffect(() => {
        setOrganizationsFromCache()
        // eslint-disable-next-line
    }, [
        appState.cachedData.organizations,
        appState.cachedData.suppliers,
        appState.cachedData.resellers,
        appState.cachedData.customers
    ]);

    useEffect(() => {
        setOrganizationRequestsFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.organization_requests]);

    useEffect(() => {
        setFilteredOrganizations(organizations);
        // eslint-disable-next-line
    }, [organizations]);

    useEffect(() => {
        setFilteredSuppliers(filteredOrganizations.filter(organization => organization['@type'] === 'Supplier'));
        setFilteredResellers(filteredOrganizations.filter(organization => organization['@type'] === 'Reseller'));
        setFilteredCustomers(filteredOrganizations.filter(organization => organization['@type'] === 'Customer'));
        // eslint-disable-next-line
    }, [filteredOrganizations]);

    const setOrganizationsFromCache = () => {
        if(
            !props.match.params.type &&
            appState.cachedData.organizations
        ) {
            setOrganizations(
                Object.keys(appState.cachedData.organizations)
                    .map(organizationId => appState.cachedData.organizations[organizationId])
            );
            if(appState.cachedData.suppliers) setOwnSuppliersId(Object.keys(appState.cachedData.suppliers));
            if(appState.cachedData.resellers) setOwnResellersId(Object.keys(appState.cachedData.resellers));
            if(appState.cachedData.customers) setOwnCustomersId(Object.keys(appState.cachedData.customers));
        } else if(
            authState.user &&
            !authState.user.roles.includes('ROLE_SUPERADMIN') &&
            appState.cachedData &&
            appState.cachedData[props.match.params.type]
        ) {
            setOrganizations(
                Object.keys(appState.cachedData[props.match.params.type])
                    .map(organizationId => appState.cachedData[props.match.params.type][organizationId])
            );
        } else if(
            authState.user &&
            authState.user.roles.includes('ROLE_SUPERADMIN') &&
            appState.cachedData &&
            appState.cachedData.organizations
        ) {
            setOrganizations(
                Object.keys(appState.cachedData.organizations)
                    .filter(organizationId => appState.cachedData.organizations[organizationId]['@id'].split('/')[2] === props.match.params.type)
                    .map(organizationId => appState.cachedData.organizations[organizationId])
            );
        }
    };

    const setOrganizationRequestsFromCache = () => {
        if(
            !props.match.params.type &&
            authState.user.organizationId &&
            appState.cachedData &&
            appState.cachedData.organization_requests
        ) {
            let tmpRequestedOrganizationsId = [], tmpRequesterOrganizationsId = [];
            Object.keys(appState.cachedData.organization_requests)
                .filter(requestId => !appState.cachedData.organization_requests[requestId].status)
                .map(requestId => {
                    if (appState.cachedData.organization_requests[requestId].requesterOrganization.id === authState.user.organizationId) {
                        return tmpRequestedOrganizationsId.push(appState.cachedData.organization_requests[requestId].requestedOrganization.id);
                    } else if (appState.cachedData.organization_requests[requestId].requestedOrganization.id === authState.user.organizationId) {
                        return tmpRequesterOrganizationsId.push(appState.cachedData.organization_requests[requestId].requesterOrganization.id);
                    }
                    return false;
                });
            setRequestedOrganizationsId(tmpRequestedOrganizationsId);
            setRequesterOrganizationsId(tmpRequesterOrganizationsId);
        }
    };

    const filterOrganizations = event => {
        event.persist();
        let tmpFilteredOrganizations = organizations.filter(organization => organization.name.toLowerCase().includes(event.target.value.toLowerCase()));
        setFilteredOrganizations(tmpFilteredOrganizations);
    };

    const handleCreate = () => {
        props.history.push(`/organizations/create/${props.match.params.type}`);
    };

    const handleMakeRequest = () => {
        fetchInitAppState();
        fetch(
            'organization_requests',
            {
                method: 'POST',
                body: {
                    requesterOrganization: `/api/organizations/${authState.user.organizationId}`,
                    requestedOrganization: `/api/organizations/${organizationId}`
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                setRequestedOrganizationsId([...requestedOrganizationsId, organizationId]);
                addRefreshResourceAppState('organization_requests');
                showMessageAppState('success', t('organization_requested'));
                setOpenDialog(false);
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleEdit = paramOrganizationId => {
        props.history.push(`/organizations/edit/${props.match.params.type}/${paramOrganizationId}`);
    };

    const handleCreateUser = paramOrganizationId => {
        props.history.push(`/users/create/${props.match.params.type}/${paramOrganizationId}`);
    };

    const handleCreateCustomDeliveryCost = paramOrganizationId => {
        props.history.push(`/custom_delivery_costs/${paramOrganizationId}`);
    };

    const handleConfirm = () => {
        handleMakeRequest();
    };

    const handleOpenDialog = paramOrganizationId => {
        setOrganizationId(paramOrganizationId);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleClickSuppliers = () => {
        setOpenSuppliers(!openSuppliers);
    };

    const handleClickResellers = () => {
        setOpenResellers(!openResellers);
    };

    const handleClickCustomers = () => {
        setOpenCustomers(!openCustomers);
    };

    const OrganizationItem = organizationItem => {
        let organization = organizationItem.organization;
        return (
            <div key={organization.id}>
                <ListItem>
                    <ListItemText
                        primary={organization.name}
                        secondary={
                            organization.mainAddress &&
                            <span>
                                <span>{organization.mainAddress.line1}</span><br />
                                {
                                    organization.mainAddress.line2 &&
                                    organization.mainAddress.line2 !== '' &&
                                    <span>
                                        <span>
                                            {organization.mainAddress.line2}
                                        </span>
                                        <br />
                                    </span>
                                }
                                {
                                    organization.mainAddress.line3 &&
                                    organization.mainAddress.line3 !== '' &&
                                    <span>
                                        <span>
                                            {organization.mainAddress.line3}
                                        </span>
                                        <br />
                                    </span>
                                }
                                {
                                    organization.mainAddress.countryProvince &&
                                    organization.mainAddress.countryProvince !== '' &&
                                    <span>
                                        <span>
                                            {organization.mainAddress.countryProvince}
                                        </span>
                                        <br />
                                    </span>
                                }
                                <span>{organization.mainAddress.postalCode}</span>&nbsp;
                                <span>{organization.mainAddress.city}</span><br />
                                <span>{organization.mainAddress.country}</span><br />
                            </span>
                        }
                    />
                    <ListItemSecondaryAction>
                        {
                            props.match.params.type &&
                            (
                                authState.user.roles.includes('ROLE_SUPPLIER') ||
                                (
                                    authState.user.roles.includes('ROLE_RESELLER') &&
                                    props.match.params.type === 'customers'
                                )
                            ) &&
                            <div>
                                <IconButton
                                    edge="end"
                                    aria-label="add_user"
                                    onClick={() => handleCreateUser(organizationItem.organization.id)}
                                    className={classes.icon}
                                >
                                    <PersonAdd />
                                </IconButton>
                                <IconButton
                                    edge="end"
                                    aria-label="add_custom_delivery_cost"
                                    onClick={() => handleCreateCustomDeliveryCost(organizationItem.organization.id)}
                                    className={classes.icon}
                                >
                                    <LocalShipping />
                                </IconButton>
                            </div>
                        }
                        {
                            props.match.params.type &&
                            authState.user.roles.includes('ROLE_SUPERADMIN') &&
                            <IconButton
                                edge="end"
                                aria-label="edit"
                                onClick={() => handleEdit(organizationItem.organization.id)}
                                className={classes.icon}
                            >
                                <Edit />
                            </IconButton>
                        }
                        {
                            !props.match.params.type &&
                            organization['@type'].toLowerCase() !== authState.user.organizationType &&
                            !ownSuppliersId.includes(organization.id.toString()) &&
                            !ownResellersId.includes(organization.id.toString()) &&
                            !ownCustomersId.includes(organization.id.toString()) &&
                            !requestedOrganizationsId.includes(organization.id) &&
                            !requesterOrganizationsId.includes(organization.id) &&
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="make_request"
                                    onClick={() => handleOpenDialog(organization.id)}
                                    className={classes.icon}
                                >
                                    <Add />
                                </IconButton>
                            </ListItemSecondaryAction>
                        }
                        {
                            !props.match.params.type &&
                            (
                                ownSuppliersId.includes(organization.id.toString()) ||
                                ownResellersId.includes(organization.id.toString()) ||
                                ownCustomersId.includes(organization.id.toString())
                            ) &&
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="already_own_organizations"
                                    disabled
                                    className={classes.icon}
                                >
                                    <Done />
                                </IconButton>
                            </ListItemSecondaryAction>
                        }
                        {
                            !props.match.params.type &&
                            (
                                requestedOrganizationsId.includes(organization.id) ||
                                requesterOrganizationsId.includes(organization.id)
                            ) &&
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="request_in_progress"
                                    disabled
                                    className={classes.icon}
                                >
                                    <QueryBuilder />
                                </IconButton>
                            </ListItemSecondaryAction>
                        }
                        {
                            !props.match.params.type &&
                            organization['@type'].toLowerCase() === authState.user.organizationType &&
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="cant_make_request"
                                    disabled
                                    className={classes.icon}
                                >
                                    <NotInterested />
                                </IconButton>
                            </ListItemSecondaryAction>
                        }
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <IconButton disabled aria-label="search">
                    <Search />
                </IconButton>
                <InputBase
                    className={classes.input}
                    placeholder={t('filter')}
                    inputProps={{ 'aria-label': 'filter organizations list' }}
                    onChange={filterOrganizations}
                />
            </Paper>
            {
                organizations &&
                organizations.length === 0 &&
                !appState.isError &&
                (
                    appState.isLoading ||
                    appState.isRefreshing
                ) &&
                <div>{t('loading')}</div>
            }
            {
                props.match.params.type &&
                <MaterialList component="nav" className={classes.list} aria-label={t(props.match.params.type)}>
                    {
                        filteredOrganizations &&
                        filteredOrganizations.length > 0 &&
                        filteredOrganizations.map(organization => <OrganizationItem organization={organization} key={organization.id} />)
                    }
                    {
                        filteredOrganizations &&
                        filteredOrganizations.length === 0 &&
                        !appState.isError &&
                        !appState.isLoading &&
                        !appState.isRefreshing &&
                        <div>{t('no_result')}</div>
                    }
                </MaterialList>
            }
            {
                !props.match.params.type &&
                <MaterialList component="nav" className={classes.list} aria-label={t('organizations')}>
                    <ListItem button onClick={handleClickSuppliers}>
                        <ListItemText primary={t('suppliers')} />
                        {openSuppliers ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openSuppliers} timeout="auto" unmountOnExit>
                        <MaterialList component="nav" className={classes.listPadded} aria-label={t('suppliers')}>
                        {
                            filteredSuppliers &&
                            filteredSuppliers.length > 0 &&
                            filteredSuppliers
                                .filter(organization => organization.id !== authState.user.organizationId)
                                .map(organization => <OrganizationItem organization={organization} key={organization.id} />)
                        }
                        {
                            filteredSuppliers &&
                            filteredSuppliers.length === 0 &&
                            !appState.isError &&
                            !appState.isLoading &&
                            !appState.isRefreshing &&
                            <div>{t('no_result')}</div>
                        }
                        </MaterialList>
                    </Collapse>
                    <ListItem button onClick={handleClickResellers}>
                        <ListItemText primary={t('resellers')} />
                        {openResellers ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openResellers} timeout="auto" unmountOnExit>
                        <MaterialList component="nav" className={classes.listPadded} aria-label={t('resellers')}>
                        {
                            filteredResellers &&
                            filteredResellers.length > 0 &&
                            filteredResellers
                                .filter(organization => organization.id !== authState.user.organizationId)
                                .map(organization => <OrganizationItem organization={organization} key={organization.id} />)
                        }
                        {
                            filteredResellers &&
                            filteredResellers.length === 0 &&
                            !appState.isError &&
                            !appState.isLoading &&
                            !appState.isRefreshing &&
                            <div>{t('no_result')}</div>
                        }
                        </MaterialList>
                    </Collapse>
                    <ListItem button onClick={handleClickCustomers}>
                        <ListItemText primary={t('customers')} />
                        {openCustomers ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openCustomers} timeout="auto" unmountOnExit>
                        <MaterialList component="nav" className={classes.listPadded} aria-label={t('customers')}>
                        {
                            filteredCustomers &&
                            filteredCustomers.length > 0 &&
                            filteredCustomers
                                .filter(organization => organization.id !== authState.user.organizationId)
                                .map(organization => <OrganizationItem organization={organization} key={organization.id} />)
                        }
                        {
                            filteredCustomers &&
                            filteredCustomers.length === 0 &&
                            !appState.isError &&
                            !appState.isLoading &&
                            !appState.isRefreshing &&
                            <div>{t('no_result')}</div>
                        }
                        </MaterialList>
                    </Collapse>
                </MaterialList>
            }
            {
                (
                    props.match.params.type &&
                    (
                        authState.user.roles.includes('ROLE_SUPERADMIN') ||
                        authState.user.roles.includes('ROLE_ADMIN') ||
                        authState.user.roles.includes('ROLE_SUPPLIER') ||
                        authState.user.roles.includes('ROLE_RESELLER')
                    )
                ) && (
                    <Fab
                        color="primary"
                        aria-label="create"
                        className={classes.addFab}
                        onClick={handleCreate}
                    >
                        <Add />
                    </Fab>
                )
            }
            <ConfirmDialog
                open={openDialog}
                onClose={handleCloseDialog}
                onConfirm={handleConfirm}
                title='confirm_organization_request'
                content='confirm_organization_request_content'
                {...props}
            />
        </div>
    )
};

export default List;