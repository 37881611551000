import React, {useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Button, Container, FormControl, FormGroup, Grid, makeStyles, TextField} from "@material-ui/core";
import {AppContext, AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));

const Create = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const handleCreate = () => {
        fetchInitAppState();
        fetch(
            'addresses',
            {
                method: 'POST',
                body: {
                    line1: inputs.line1,
                    line2: inputs.line2,
                    line3: inputs.line3,
                    postalCode: inputs.postalCode,
                    city: inputs.city,
                    countryProvince: inputs.countryProvince,
                    country: inputs.country,
                    comment: inputs.comment,
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                forceRefreshResourceAppState('addresses');
                showMessageAppState('success', t('address_added'));
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const {inputs, handleInputChange, handleSubmit} = useForm(handleCreate);
    const {fetch} = useDataAccess();

    useEffect(() => {
        setPageInfos('new_address', false);
        if(
            authState.user &&
            authState.user.roles.includes('ROLE_SUPERADMIN')
        ) {
            addRefreshResourceAppState('organizations');
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Container>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
            >
                {
                    appState.isError &&
                    appState.error &&
                    (
                        <Grid item xs={12} className="text-center">
                            <div className="alert alert-danger" role="alert">
                                <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
                                {appState.error}
                            </div>
                        </Grid>
                    )
                }
                <form onSubmit={handleSubmit} className="container">
                    <FormGroup>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="line1"
                                name="line1"
                                label={t('line1')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.line1 ? inputs.line1 : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="line2"
                                name="line2"
                                label={t('line2')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.line2 ? inputs.line2 : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="line3"
                                name="line3"
                                label={t('line3')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.line3 ? inputs.line3 : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="postalCode"
                                name="postalCode"
                                label={t('postal_code')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.postalCode ? inputs.postalCode : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="city"
                                name="city"
                                label={t('city')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.city ? inputs.city : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="countryProvince"
                                name="countryProvince"
                                label={t('country_province')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.countryProvince ? inputs.countryProvince : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="country"
                                name="country"
                                label={t('country')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.country ? inputs.country : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="comment"
                                name="comment"
                                label={t('comment')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.comment ? inputs.comment : ''}
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            >
                                {t('create')}
                            </Button>
                        </FormControl>
                    </FormGroup>
                </form>
            </Grid>
        </Container>
    )
};

export default Create;