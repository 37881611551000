import React from 'react';
import {Route} from 'react-router-dom';
import {Wrapper} from '../hooks/common';
import {Create, List, Update} from '../hooks/user/';

export default [
  <Route path="/users/create/:type" component={props => <Wrapper component={Create} {...props} />} exact key="create" />,
  <Route path="/users/create/:type/:id" component={props => <Wrapper component={Create} {...props} />} exact key="create" />,
  <Route path="/users/edit/:id" component={props => <Wrapper component={Update} {...props} />} exact key="update" />,
  <Route path="/users" component={props => <Wrapper component={List} {...props} />} exact strict key="list" />,
  <Route path="/users/:page" component={props => <Wrapper component={List} {...props} />} exact strict key="page" />
];
