import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Collapse,
    Divider,
    Fab,
    IconButton,
    List as MaterialList,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles
} from "@material-ui/core";
import {Add, Check, Delete, Edit, ExpandLess, ExpandMore} from '@material-ui/icons';
import {ConfirmDialog, OrganizationTypeDialog} from '../dialog';
import {AppContext} from '../../context';
import {useDataAccess} from '../custom_hooks';
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    list: {
        marginBottom: theme.spacing(5),
    },
    listPadded: {
        marginLeft: theme.spacing(2),
    },
    addFab: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    icon: {
        color: theme.palette.primary.main,
    },
}));

const List = props => {
    const { t } = useTranslation();
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        updateCachedDataAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const {fetch} = useDataAccess();
    const [openDialogOrganizationType, setOpenDialogOrganizationType] = useState(false);
    const [openDialogEnable, setOpenDialogEnable] = useState(false);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);
    const [openDisabledUsers, setOpenDisabledUsers] = useState(true);
    const [openUsersWithNoOrganizations, setOpenUsersWithNoOrganizations] = useState(true);
    const [openEnabledUsers, setOpenEnabledUsers] = useState(true);
    const [users, setUsers] = useState([]);
    const [disabledUsers, setDisabledUsers] = useState([]);
    const [usersWithNoOrganization, setUsersWithNoOrganization] = useState([]);
    const [enabledUsers, setEnabledUsers] = useState([]);
    const [userId, setUserId] = useState(0);
    const [userConfirmationToken, setUserConfirmationToken] = useState(null);

    useEffect(() => {
        setPageInfos('users', true);
        setUsersFromCache();
        addRefreshResourceAppState('users');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setUsersFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.users]);

    useEffect(() => {
        sortUsers();
        // eslint-disable-next-line
    }, [users]);

    const setUsersFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.users
        ) {
            setUsers(
                Object.keys(appState.cachedData.users)
                    .map(userId => appState.cachedData.users[userId])
            );
        }
    };

    const sortUsers = () => {
        let tmpDisabledUsers = [],
            tmpUsersWithNoOrganization = [],
            tmpEnabledUsers = [];

        if(users && users.length > 0) {
            users.map(user => {
                if(!user.enabled) {
                    return tmpDisabledUsers.push(user);
                } else if(!user.organization) {
                    return tmpUsersWithNoOrganization.push(user);
                }
                return tmpEnabledUsers.push(user);
            });
        }

        setDisabledUsers(tmpDisabledUsers);
        setUsersWithNoOrganization(tmpUsersWithNoOrganization);
        setEnabledUsers(tmpEnabledUsers);
    };
    
    const handleEdit = paramUserId => {
        props.history.push(`/users/edit/${paramUserId}`);
    };

    const enableUser = () => {
        if(userConfirmationToken !== null) {
            fetchInitAppState();
            fetch(
                `confirm-user/${userConfirmationToken}`,
                {
                    method: 'GET'
                }
            )
                .then(() => {
                    fetchSuccessAppState();
                    forceRefreshResourceAppState('users');
                    showMessageAppState('success', t('user_enabled'));
                    handleCloseDialogEnable();
                })
                .catch(error => {
                    fetchErrorAppState(parseError(error));
                });
        }
    };

    const deleteUser = () => {
        if(userId > 0) {
            fetchInitAppState();
            fetch(
                `users/${userId}`,
                {
                    method: 'DELETE'
                }
            )
                .then(() => {
                    fetchSuccessAppState();
                    setUsers([...users.filter(user => user.id !== parseInt(userId))]);
                    updateCachedDataAppState('users', userId, null);
                    showMessageAppState('success', t('user_deleted'));
                    handleCloseDialogDelete();
                })
                .catch(error => {
                    fetchErrorAppState(parseError(error));
                });
        }
    };

    const handleSelectOrganizationType = organizationType => {
        props.history.push(`/users/create/${organizationType}`);
    };

    const handleEnableUser = () => {
        enableUser();
    };

    const handleDeleteUser = () => {
        deleteUser();
    };

    const handleOpenDialogOrganizationType = () => {
        setOpenDialogOrganizationType(true);
    };

    const handleCloseDialogOrganizationType = () => {
        setOpenDialogOrganizationType(false);
    };

    const handleOpenDialogEnable = paramUserConfirmationToken => {
        setUserConfirmationToken(paramUserConfirmationToken);
        setOpenDialogEnable(true);
    };

    const handleCloseDialogEnable = () => {
        setOpenDialogEnable(false);
    };

    const handleOpenDialogDelete = paramUserId => {
        setUserId(paramUserId);
        setOpenDialogDelete(true);
    };

    const handleCloseDialogDelete = () => {
        setOpenDialogDelete(false);
    };

    const handleClickDisabledUsers = () => {
        setOpenDisabledUsers(!openDisabledUsers);
    };

    const handleClickUsersWithNoOrganizations = () => {
        setOpenUsersWithNoOrganizations(!openUsersWithNoOrganizations);
    };

    const handleClickEnabledUsers = () => {
        setOpenEnabledUsers(!openEnabledUsers);
    };

    const UserItem = props => {
        return (
            <div key={props.user.id}>
                <ListItem>
                    <ListItemText
                        primary={`${props.user.firstName} ${props.user.lastName}`}
                        secondary={`${props.user.email} / ${props.user.organization ? props.user.organization.name : ''}`}
                    />
                    <ListItemSecondaryAction>
                        {
                            props.user && !props.user.enabled &&
                            <IconButton
                                edge="end"
                                aria-label="confirm"
                                onClick={() => handleOpenDialogEnable(props.user.confirmationToken)}
                                className={classes.icon}
                            >
                                <Check />
                            </IconButton>
                        }
                        <IconButton
                            edge="end"
                            aria-label="edit"
                            onClick={() => handleEdit(props.user.id)}
                            className={classes.icon}
                        >
                            <Edit />
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => handleOpenDialogDelete(props.user.id)}
                            className={classes.icon}
                        >
                            <Delete />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />
            </div>
        )
    };

    return (
        <div className={classes.root}>
            {
                users &&
                users.length === 0 &&
                !appState.isError &&
                (
                    appState.isLoading ||
                    appState.isRefreshing
                ) &&
                <div>{t('loading')}</div>
            }
            <MaterialList component="nav" className={classes.list} aria-label={t('users')}>
                <ListItem button onClick={handleClickDisabledUsers}>
                    <ListItemText primary={t('disabled_users')} />
                    {openDisabledUsers ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openDisabledUsers} timeout="auto" unmountOnExit>
                    <MaterialList component="nav" className={classes.listPadded} aria-label={t('disabled_users')}>
                    {
                        disabledUsers &&
                        disabledUsers.length > 0 &&
                        disabledUsers.map(user => <UserItem user={user} key={user.id} />)
                    }
                    {
                        disabledUsers &&
                        disabledUsers.length === 0 &&
                        !appState.isError &&
                        !appState.isLoading &&
                        !appState.isRefreshing &&
                        <div>{t('no_result')}</div>
                    }
                    </MaterialList>
                </Collapse>
                <ListItem button onClick={handleClickUsersWithNoOrganizations}>
                    <ListItemText primary={t('users_with_no_organization')} />
                    {openUsersWithNoOrganizations ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openUsersWithNoOrganizations} timeout="auto" unmountOnExit>
                    <MaterialList component="nav" className={classes.listPadded} aria-label={t('users_with_no_organization')}>
                    {
                        usersWithNoOrganization &&
                        usersWithNoOrganization.length > 0 &&
                        usersWithNoOrganization.map(user => <UserItem user={user} key={user.id} />)
                    }
                    {
                        usersWithNoOrganization &&
                        usersWithNoOrganization.length === 0 &&
                        !appState.isError &&
                        !appState.isLoading &&
                        !appState.isRefreshing &&
                        <div>{t('no_result')}</div>
                    }
                    </MaterialList>
                </Collapse>
                <ListItem button onClick={handleClickEnabledUsers}>
                    <ListItemText primary={t('enabled_users')} />
                    {openEnabledUsers ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openEnabledUsers} timeout="auto" unmountOnExit>
                    <MaterialList component="nav" className={classes.listPadded} aria-label={t('enabled_users')}>
                    {
                        enabledUsers &&
                        enabledUsers.length > 0 &&
                        enabledUsers.map(user => <UserItem user={user} key={user.id} />)
                    }
                    {
                        enabledUsers &&
                        enabledUsers.length === 0 &&
                        !appState.isError &&
                        !appState.isLoading &&
                        !appState.isRefreshing &&
                        <div>{t('no_result')}</div>
                    }
                    </MaterialList>
                </Collapse>
            </MaterialList>
            <Fab
                color="primary"
                aria-label="create"
                className={classes.addFab}
                onClick={handleOpenDialogOrganizationType}
            >
                <Add />
            </Fab>
            <OrganizationTypeDialog
                open={openDialogOrganizationType}
                onClose={handleCloseDialogOrganizationType}
                onClick={handleSelectOrganizationType}
                {...props}
            />
            <ConfirmDialog
                open={openDialogEnable}
                onClose={handleCloseDialogEnable}
                onConfirm={handleEnableUser}
                title='confirm_enable'
                content='confirm_user_enable_content'
                {...props}
            />
            <ConfirmDialog
                open={openDialogDelete}
                onClose={handleCloseDialogDelete}
                onConfirm={handleDeleteUser}
                title='confirm_delete'
                content='confirm_user_delete_content'
                {...props}
            />
        </div>
    )
};

export default List;