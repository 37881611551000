import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles
} from "@material-ui/core";
import {AppContext} from '../../context';
import {useDataAccess} from "../custom_hooks";
import {parseError} from "../../utils/hydraParser";
import {exportCSVFile} from "../../utils/csvHandler";

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(2),
        color: theme.palette.text.customPrimary,
    },
    input: {
        display: 'none',
        color: theme.palette.text.customPrimary,
    },
    text: {
        color: theme.palette.text.customPrimary,
    },
}));

const ExportFileDialog = props => {
    const { t } = useTranslation();
    const classes = useStyles();
    const {
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState
    } = useContext(AppContext);
    const { fetch } = useDataAccess();

    const handleConfirm = () => {
        const headers = new Headers();
        headers.set('Accept', 'text/csv');
        headers.set('Content-Type', 'text/csv');
        fetchInitAppState();
        fetch(
            `products/export?mainCatalog=${props.idCatalog}`,
            {
                method: 'GET',
                headers,
                responseType: 'blob'
            }
        )
            .then(response => {
                return response.text();
            })
            .then(responseText => {
                exportCSVFile(responseText, 'export');
                fetchSuccessAppState();
                props.onConfirm("success");
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleCancel = () => {
        props.onClose();
    };

    const handleClose = () => {
        props.onClose();
    };

    return (
        <Dialog
            fullWidth
            maxWidth='xs'
            onClose={() => handleClose}
            aria-labelledby="dialog-title"
            open={props.open}
        >
            <DialogTitle id="dialog-title" className={classes.text}>{t(props.title)}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" className={classes.text}>
                    {t(props.content)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" className={classes.button}>
                    {t('cancel')}
                </Button>
                <Button onClick={handleConfirm} color="primary" className={classes.button}>
                    {t('submit')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExportFileDialog;