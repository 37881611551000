import React, {useContext, useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {Container, Grid, Typography, makeStyles} from "@material-ui/core";
import {AppContext, AuthContext} from '../../context';
import Logo from "../common/Logo";
import ScanPng from './ScanPng.png';

const useStyles = makeStyles(() => ({
    root: {
        width: '100%',
        height: '80vh',
    },
    scanPng: {
        maxWidth: '96px',
        border: '1px solid white',
        borderRadius: '15px',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
        transition: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
        '&:hover': {
            cursor: 'pointer',
            boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)'
        }
    },
}));

const Home = props => {
    const { authState } = useContext(AuthContext);
    const {
        setPageInfos
    } = useContext(AppContext);
    const { t } = useTranslation();
    const classes = useStyles();

    useEffect(() => {
        setPageInfos('home', true);
        // eslint-disable-next-line
    }, []);

    const handleScanProduct = () => {
        props.history.push('/scan');
    };

    return (
        <div className={classes.root}>
            <Container>
                <Grid
                    container
                    direction="column"
                    justify="space-between"
                    alignItems="center"
                    className={classes.root}
                >
                    <Typography component="h1" className="text-center">
                        {t('welcome')} {authState.user.firstName} {authState.user.lastName}
                    </Typography>
                    <Logo />
                    <img
                        src={ScanPng}
                        alt={'scan'}
                        className={classes.scanPng}
                        onClick={handleScanProduct}
                    />
                </Grid>
            </Container>
        </div>
    )
};

export default Home;