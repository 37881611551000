import {useEffect, useReducer} from 'react';
import * as AuthReducer from "../../store/hooks_state/authReducer";
import * as ACTION_TYPES from '../../store/actions/action_types';

const useAuthApi = () => {
    const localState = JSON.parse(localStorage.getItem('authState'));
    const [authState, dispatch] = useReducer(AuthReducer.authReducer, localState || AuthReducer.initialState);

    useEffect(() => {
        localStorage.setItem('authState', JSON.stringify(authState));
    }, [authState]);

    const initAuthReducer = () => {
        dispatch({ type: ACTION_TYPES.AUTH_REDUCER_INIT });
    };

    const fetchAuthInit = () => {
        dispatch({ type: ACTION_TYPES.AUTH_LOADING });
    };

    const fetchAuthError = error => {
        dispatch({ type: ACTION_TYPES.AUTH_ERROR, error: error.message });
    };

    const fetchLogin = data => {
        dispatch({ type: ACTION_TYPES.LOGIN_SUCCESS, payload: data });
    };

    const fetchRegister = () => {
        dispatch({ type: ACTION_TYPES.REGISTER_SUCCESS });
    };

    const fetchCreatePassword = () => {
        dispatch({ type: ACTION_TYPES.CREATE_PASSWORD_SUCCESS });
    };

    const fetchConfirmUser = () => {
        dispatch({ type: ACTION_TYPES.CONFIRM_USER_SUCCESS });
    };

    const fetchRefresh = (key, data) => {
        dispatch({ type: ACTION_TYPES.AUTH_REFRESH, key: key, data: data });
    };

    const logout = () => {
        try {
            localStorage.clear();
            caches.delete('api-cache')
                .catch(error => console.log(error))
        } catch(error) {
            console.log(error);
        }
        initAuthReducer();
    };

    return {
        authState,
        initAuthReducer,
        fetchAuthInit,
        fetchAuthError,
        fetchLogin,
        fetchRegister,
        fetchCreatePassword,
        fetchConfirmUser,
        fetchRefresh,
        logout
    };
};

export default useAuthApi;