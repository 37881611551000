import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {GridList, GridListTile, GridListTileBar, IconButton, makeStyles, Typography} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import defaultImage from './icon.png';
import {Info} from '@material-ui/icons';
import {AppContext, AuthContext} from '../../context';
import {useDataAccess} from "../custom_hooks";
import {getIdFromUri} from "../../utils/hydraParser";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        marginTop: theme.spacing(1),
    },
    listHeaderTitle: {
        marginTop: theme.spacing(1),
    },
    gridList: {
        transform: 'translateZ(0)',
    },
    gridListTileImage: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
    showProduct: {
        marginLeft: 'auto',
    },
    avatar: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    },
    productItem: {
        margin: 'auto',
        minWidth: 256,
        maxWidth: 320,
        textAlign: 'left',
    },
    text: {
        color: theme.palette.text.primary
    },
    icon: {
        color: theme.palette.text.primary
    },
}));

const List = props => {
    const { t } = useTranslation();
    const classes = useStyles();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        updateCachedDataAppState,
        fetchErrorAppState,
    } = useContext(AppContext);
    const {fetch} = useDataAccess();
    const [cols, setCols] = useState(2);
    const [mainProducts, setMainProducts] = useState([]);
    const [spareParts, setSpareParts] = useState([]);
    const [mainImagesSrc, setMainImagesSrc] = useState([]);
    const [loadingImagesSrc, setLoadingImagesSrc] = useState(false);
    const mdBp = useMediaQuery(theme => theme.breakpoints.up('md'));
    const lgBp = useMediaQuery(theme => theme.breakpoints.up('lg'));

    useEffect(() => {
        if(lgBp) return setCols(4);
        if(mdBp && !lgBp) return setCols(2);
        if(!mdBp) return setCols(1);
    }, [mdBp, lgBp]);

    useEffect(() => {
        const abortController = new AbortController();

        if(
            props.products &&
            props.products.length > 0
        ) {
            setProducts();
            setMainImagesSrcFromCache();
            setMainImagesSrcFromApi(abortController.signal);
        }

        return () => {
            abortController.abort();
        };
        // eslint-disable-next-line
    }, [props.products]);

    useEffect(() => {
        setMainImagesSrcFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.images, loadingImagesSrc]);

    useEffect(() => {
        if(mainImagesSrc && mainImagesSrc.length > 0) {
            setProducts();
        }
        // eslint-disable-next-line
    }, [mainImagesSrc]);

    const setProducts = () => {
        if(
            props.products &&
            props.products.length > 0
        ) {
            setMainProducts(props.products.filter(product => !product.isSparePart));
            setSpareParts(props.products.filter(product => product.isSparePart));
        }
    };

    const setMainImagesSrcFromCache = () => {
        if(
            props.products &&
            props.products.length > 0 &&
            appState.cachedData &&
            appState.cachedData.images
        ) {
            if(!loadingImagesSrc) {
                const tmpMainImagesSrc = [];
                props.products.filter(product => product.mainImage !== null)
                    .map(product => {
                        const currentMainImageId = getIdFromUri(product.mainImage);
                        return tmpMainImagesSrc[currentMainImageId] = appState.cachedData.images[currentMainImageId];
                    });
                setMainImagesSrc(tmpMainImagesSrc);
            }
        } else {
            setMainImagesSrc([]);
        }
    };

    const setMainImagesSrcFromApi = signal => {
        if(props.products.length > 0 && !loadingImagesSrc) {
            setLoadingImagesSrc(true);
            const productsWithMainImage = props.products.filter(product => product.mainImage !== null);
            Promise.all(productsWithMainImage.map(product => fetch(`media_objects/${getIdFromUri(product.mainImage)}`, { method: 'GET', signal: signal })))
                .then(response => Promise.all(response.map(resp => resp.json())))
                .then(values => {
                    values.map(json => {
                        return updateCachedDataAppState('images', getIdFromUri(json['@id']), `${process.env.REACT_APP_API_ENTRYPOINT}${json.contentUrl}`);
                    });
                    setLoadingImagesSrc(false);
                })
                .catch(errors => {
                    if (Array.isArray(errors) && errors.length > 0) {
                        errors.map(error => fetchErrorAppState(error));
                    } else {
                        fetchErrorAppState(errors);
                    }
                });
        }
    };

    const handleShow = product => {
        props.history.push(`/catalogs/show_product/${product.id}`);
    };

    const productList = productList => (
        <div className={classes.root}>
            <GridList
                cellHeight={256}
                spacing={20}
                cols={cols}
                className={classes.gridList}
            >
                {
                    productList &&
                    productList.length > 0 &&
                    productList.map(product => {
                            return (
                                <GridListTile
                                    className={classes.productItem}
                                    onClick={() => handleShow(product)}
                                    key={product.id}
                                >
                                    {
                                        mainImagesSrc &&
                                        mainImagesSrc.length > 0 &&
                                        mainImagesSrc[getIdFromUri(product.mainImage)] &&
                                        product.mainImage &&
                                        <img
                                            src={mainImagesSrc[getIdFromUri(product.mainImage)]}
                                            alt={product.name}
                                            className={classes.gridListTileImage}
                                        />
                                    }
                                    {
                                        !product.mainImage &&
                                        <img
                                            src={defaultImage}
                                            alt={product.name}
                                            className={classes.gridListTileImage}
                                        />
                                    }
                                    <GridListTileBar
                                        title={product.name}
                                        subtitle={
                                            (
                                                authState.user.organizationId &&
                                                (
                                                    authState.user.roles.includes('ROLE_SUPERADMIN') ||
                                                    authState.user.organizationId === getIdFromUri(product.mainCatalog.owner)
                                                )
                                            ) ?
                                                `${t('reference')} : ${product.reference}` :
                                                `${t('reference')} : ${product.commercialReference}`
                                        }
                                        actionIcon={
                                            <IconButton aria-label={`info about ${product.name}`} className={classes.icon}>
                                                <Info />
                                            </IconButton>
                                        }
                                        classes={{
                                            title: classes.text,
                                            subtitle: classes.text
                                        }}
                                    />
                                </GridListTile>
                            )
                        }
                    )
                }
            </GridList>
        </div>
    )

    return (
        <div>
            <Typography className={classes.listHeaderTitle}>
                {t('products')}
            </Typography>
            {productList(mainProducts)}
            <Typography className={classes.listHeaderTitle}>
                {t('spare_parts')}
            </Typography>
            {productList(spareParts)}
        </div>
    )
};

export default List;