import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Button,
    Container,
    FormControl,
    FormGroup,
    Grid,
    InputLabel,
    makeStyles,
    NativeSelect,
    TextField
} from "@material-ui/core";
import {AppContext, AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    option: {
        color: theme.palette.text.customPrimary,
    },
}));

const Update = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        setPageInfos,
        showMessageAppState
    } = useContext(AppContext);
    const classes = useStyles();
    const [user, setUser] = useState(null);
    const [organizations, setOrganizations] = useState([]);
    const {fetch} = useDataAccess();
    const handleUpdate = () => {
        fetchInitAppState();
        fetch(
            `users/${props.match.params.id}`,
            {
                method: 'PUT',
                body: {
                    email: inputs.email,
                    firstName: inputs.firstName,
                    lastName: inputs.lastName,
                    roles: (authState.user && authState.user.roles.includes('ROLE_SUPERADMIN')) ? [inputs.roles] : ['ROLE_CUSTOMER'],
                    organization: (authState.user && authState.user.roles.includes('ROLE_SUPERADMIN')) ? inputs.organization : user.organization
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                forceRefreshResourceAppState(`users/${props.match.params.id}`);
                showMessageAppState('success', t('user_updated'));
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const {inputs, handleInputChange, handleSubmit, setInputs} = useForm(handleUpdate);

    useEffect(() => {
        setPageInfos('edit_user', false);
        setUserFromCache();
        setOrganizationsFromCache()
        addRefreshResourceAppState(`users/${props.match.params.id}`);
        addRefreshResourceAppState('organizations');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(user) {
            setInputs(inputs => ({...inputs, ...{...user, 'roles': user.roles[0]}}));
        }
        // eslint-disable-next-line
    }, [user]);

    const setUserFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.users &&
            appState.cachedData.users[props.match.params.id]
        ) {
            setUser(appState.cachedData.users[props.match.params.id]);
        } else {
            setUser(null);
        }
    };

    const setOrganizationsFromCache = () => {
        if(
            appState.cachedData.organizations &&
            authState.user.roles.includes('ROLE_SUPERADMIN')
        ) {
            setOrganizations(
                Object.keys(appState.cachedData.organizations)
                    .map(organizationId => appState.cachedData.organizations[organizationId])
            );
        }
    };

    return (
        <Container>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
            >
                {
                    appState.isError &&
                    appState.error &&
                    (
                        <Grid item xs={12} className="text-center">
                            <div className="alert alert-danger" role="alert">
                                <span className="fa fa-exclamation-triangle" aria-hidden="true"/>{' '}
                                {appState.error}
                            </div>
                        </Grid>
                    )
                }
                <form onSubmit={handleSubmit} className="container">
                    <FormGroup>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="email"
                                name="email"
                                label={t('email_address')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.email ? inputs.email : ''}
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="firstName"
                                name="firstName"
                                label={t('first_name')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.firstName ? inputs.firstName : ''}
                                required
                            />
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField
                                margin="normal"
                                error={appState.isError}
                                id="lastName"
                                name="lastName"
                                label={t('last_name')}
                                type="text"
                                onChange={handleInputChange}
                                value={inputs.lastName ? inputs.lastName : ''}
                                required
                            />
                        </FormControl>
                        {
                            authState.user &&
                            authState.user.roles.includes('ROLE_SUPERADMIN') &&
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="rolesnpm">
                                    {t('role')}
                                </InputLabel>
                                <NativeSelect
                                    required
                                    disabled={
                                        appState.isLoading &&
                                        appState.isRefreshing
                                    }
                                    value={inputs.roles ? inputs.roles : ''}
                                    onChange={handleInputChange}
                                    inputProps={{
                                        name: 'roles',
                                        id: 'roles',
                                    }}
                                >
                                    <option value='ROLE_CUSTOMER' className={classes.option}>{t('customer')}</option>
                                    <option value='ROLE_RESELLER' className={classes.option}>{t('reseller')}</option>
                                    <option value='ROLE_SUPPLIER' className={classes.option}>{t('supplier')}</option>
                                    <option value='ROLE_ADMIN' className={classes.option}>{t('ROLE_ADMIN')}</option>
                                    <option value='ROLE_SUPERADMIN' className={classes.option}>{t('ROLE_SUPERADMIN')}</option>
                                </NativeSelect>
                            </FormControl>
                        }
                        {
                            authState.user &&
                            authState.user.roles.includes('ROLE_SUPERADMIN') &&
                            <FormControl className={classes.formControl}>
                                <InputLabel htmlFor="organization">
                                    {t('organization')}
                                </InputLabel>
                                <NativeSelect
                                    required
                                    disabled={
                                        appState.isLoading &&
                                        appState.isRefreshing
                                    }
                                    value={inputs.organization ? inputs.organization['@id'] : ''}
                                    onChange={handleInputChange}
                                    inputProps={{
                                        name: 'organization',
                                        id: 'organization',
                                    }}
                                >
                                    <option value='' />
                                    {
                                        organizations &&
                                        organizations.length > 0 &&
                                        organizations.map(organization => {
                                            return <option
                                                value={organization['@id']}
                                                key={organization['@id']}
                                                className={classes.option}>
                                                    {organization.name}
                                                </option>
                                        })
                                    }
                                </NativeSelect>
                            </FormControl>
                        }
                        <FormControl className={classes.formControl}>
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                disabled={
                                    appState.isLoading &&
                                    appState.isRefreshing
                                }
                            >
                                {t('update')}
                            </Button>
                        </FormControl>
                    </FormGroup>
                </form>
            </Grid>
        </Container>
    )
};

export default Update;