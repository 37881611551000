import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AppContext} from '../../context';
import {useDataAccess} from '../custom_hooks';
import {parseError} from "../../utils/hydraParser";
import {ManageLinks} from "../common";

const Links = props => {
    const { t } = useTranslation();
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const {fetch} = useDataAccess();
    const [product, setProduct] = useState(null);

    useEffect(() => {
        setPageInfos('product_links', false);
        setProductFromCache();
        addRefreshResourceAppState(`products/${props.match.params.id}`);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setProductFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.products]);

    const setProductFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.products &&
            appState.cachedData.products[props.match.params.id]
        ) {
            setProduct(appState.cachedData.products[props.match.params.id]);
        } else {
            setProduct(null);
        }
    };

    const submitLinks = grantedOrganizations => {
        fetchInitAppState();
        fetch(
            `products/${props.match.params.id}`,
            {
                method: 'PUT',
                body: {
                    grantedOrganizations: grantedOrganizations
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                forceRefreshResourceAppState(`products/${props.match.params.id}`);
                showMessageAppState('success', t('links_updated'));
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    return (
        product &&
        product.grantedOrganizations &&
        <ManageLinks type='product' element={product.grantedOrganizations} submit={submitLinks} />
    )
};

export default Links;