import React, {useContext, useEffect, useState} from 'react';
import {Redirect} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {
    Divider,
    Fab,
    Box,
    TextField,
    IconButton,
    List as MaterialList,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    makeStyles,
} from "@material-ui/core";
import {Add, Check, Clear, Delete, Edit} from '@material-ui/icons';
import {AppContext, AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import {ConfirmDialog} from "../dialog";
import {getIdFromUri, parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    list: {
        marginBottom: theme.spacing(5),
    },
    newValue: {
        maxWidth: '50%',
    },
    addFab: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    icon: {
        color: theme.palette.primary.main,
    },
}));

const List = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        updateCachedDataAppState,
        addRefreshResourceAppState,
        showMessageAppState,
        setPageInfos
    } = useContext(AppContext);
    const classes = useStyles();
    const {fetch} = useDataAccess();
    const [openDialog, setOpenDialog] = useState(false);
    const [customMargins, setCustomMargins] = useState([]);
    const [customMarginId, setCustomMarginId] = useState(0);
    const [visible, setVisible] = useState(0);

    const handleUpdate = () => {
        fetchInitAppState();
        fetch(
            `custom_margins/${customMarginId}`,
            {
                method: 'PUT',
                body: {
                    value: parseFloat(inputs.value)
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                updateCachedDataAppState('custom_margins', customMarginId, {...appState.cachedData.custom_margins[customMarginId], 'value': inputs.value});
                showMessageAppState('success', t('custom_margin_updated'));
                handleCancelEdit();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };
    const {inputs, handleInputChange, handleSubmit, setInputs} = useForm(handleUpdate);

    useEffect(() => {
        setPageInfos('custom_margins', false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setCustomMarginsFromCache();
        addRefreshResourceAppState(`custom_margins?product=${props.match.params.id}`);
        // eslint-disable-next-line
    }, [props.match.params.id]);

    useEffect(() => {
        setCustomMarginsFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.custom_margins]);

    useEffect(() => {
        setInputs(inputs => ({...inputs, ...customMarginId}));
        // eslint-disable-next-line
    }, [customMarginId]);

    const setCustomMarginsFromCache = () => {
        if(
            props.match.params.id &&
            appState.cachedData &&
            appState.cachedData.custom_margins
        ) {
            setCustomMargins(
                Object.keys(appState.cachedData.custom_margins)
                    .filter(customMarginId => {
                        return getIdFromUri(appState.cachedData.custom_margins[customMarginId].product) === parseInt(props.match.params.id)
                    })
                    .map(customMarginId => appState.cachedData.custom_margins[customMarginId])
            );
        }
    };

    const handleCreate = () => {
        props.history.push(`/custom_margins/create/${props.match.params.id}`);
    };

    const handleEdit = customMarginId => {
        setInputs({...inputs, value: appState.cachedData.custom_margins[customMarginId].value})
        setCustomMarginId(customMarginId);
        setVisible(customMarginId);
    };

    const handleCancelEdit = () => {
        setInputs({...inputs, value: 0})
        setCustomMarginId(0);
        setVisible(0);
    };

    const handleDelete = () => {
        fetchInitAppState();
        fetch(
            `custom_margins/${customMarginId}`,
            {
                method: 'DELETE'
            }
        )
            .then(() => {
                fetchSuccessAppState();
                setCustomMargins(customMargins.filter(customMargin => customMargin.id !== parseInt(customMarginId)));
                updateCachedDataAppState('custom_margins', customMarginId, null);
                showMessageAppState('success', t('custom_margin_deleted'));
                handleCloseDialog();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleConfirm = () => {
        handleDelete();
    }

    const handleOpenDialog = id => {
        setCustomMarginId(id);
        if(visible !== 0) {
            setVisible(0);
        }
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    if(
        !authState.user.roles.includes('ROLE_SUPERADMIN') &&
        !authState.user.roles.includes('ROLE_ADMIN') &&
        !authState.user.roles.includes('ROLE_SUPPLIER') &&
        !authState.user.roles.includes('ROLE_RESELLER')
    ) {
        return (<Redirect to='/' />)
    } else {
        return (
            <div className={classes.root}>
                <MaterialList component="nav" className={classes.list} aria-label="contacts">
                {
                    customMargins &&
                    customMargins.length > 0 &&
                    customMargins.map(customMargin => {
                        return (
                            <div key={customMargin.id}>
                                <ListItem>
                                    <ListItemText
                                        primary={customMargin.organization.name}
                                        secondary={customMargin.value}
                                    />
                                    <ListItemSecondaryAction>
                                        <Box
                                            display={visible !== customMargin.id ? 'flex' : 'none'}
                                            flexDirection="row-reverse"
                                        >
                                            <IconButton
                                                edge="end"
                                                aria-label="delete"
                                                onClick={() => handleOpenDialog(customMargin.id)}
                                                className={classes.icon}
                                                disabled={
                                                    appState.isLoading &&
                                                    appState.isRefreshing
                                                }
                                            >
                                                <Delete />
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                aria-label="edit"
                                                onClick={() => handleEdit(customMargin.id)}
                                                className={classes.icon}
                                                disabled={
                                                    appState.isLoading &&
                                                    appState.isRefreshing
                                                }
                                            >
                                                <Edit />
                                            </IconButton>
                                        </Box>
                                        <Box
                                            display={visible === customMargin.id ? 'flex' : 'none'}
                                            flexDirection="row-reverse"
                                        >
                                            <IconButton
                                                edge="end"
                                                aria-label="cancel"
                                                onClick={() => handleCancelEdit()}
                                                className={classes.icon}
                                                disabled={
                                                    appState.isLoading &&
                                                    appState.isRefreshing
                                                }
                                            >
                                                <Clear />
                                            </IconButton>
                                            <IconButton
                                                edge="end"
                                                aria-label="submit"
                                                onClick={() => handleSubmit()}
                                                className={classes.icon}
                                                disabled={
                                                    appState.isLoading &&
                                                    appState.isRefreshing
                                                }
                                            >
                                                <Check />
                                            </IconButton>
                                            <TextField
                                                error={appState.isError}
                                                id={`value-${customMargin.id}`}
                                                name="value"
                                                label={t('new_value')}
                                                type="number"
                                                inputProps={{ step: "0.01" }}
                                                onChange={handleInputChange}
                                                value={inputs.value ? inputs.value : ''}
                                                className={classes.newValue}
                                                disabled={
                                                    appState.isLoading &&
                                                    appState.isRefreshing
                                                }
                                                required
                                            />
                                        </Box>
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <Divider />
                            </div>
                        )
                    })
                }
                {
                    customMargins &&
                    customMargins.length === 0 &&
                    !appState.isError &&
                    (
                        appState.isLoading ||
                        appState.isRefreshing
                    ) &&
                    <div>{t('loading')}</div>
                }
                {
                    customMargins &&
                    customMargins.length === 0 &&
                    !appState.isError &&
                    !appState.isLoading &&
                    !appState.isRefreshing &&
                    <div>{t('no_result')}</div>
                }
                </MaterialList>
                {
                    appState &&
                    customMargins &&
                    appState.cachedData &&
                    appState.cachedData.products &&
                    appState.cachedData.products[props.match.params.id] &&
                    appState.cachedData.products[props.match.params.id].grantedOrganizations &&
                    customMargins.length < appState.cachedData.products[props.match.params.id].grantedOrganizations.length &&
                    (
                        authState.user.roles.includes('ROLE_SUPERADMIN') ||
                        authState.user.roles.includes('ROLE_SUPPLIER') ||
                        authState.user.roles.includes('ROLE_RESELLER')
                    ) && (
                        <Fab
                            color="primary"
                            aria-label="create"
                            className={classes.addFab}
                            onClick={handleCreate}
                        >
                            <Add />
                        </Fab>
                    )
                }
                <ConfirmDialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    onConfirm={handleConfirm}
                    title='confirm_delete'
                    content='confirm_custom_margin_delete_content'
                    {...props}
                />
            </div>
        )
    }
};

export default List;