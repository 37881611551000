import {useContext} from 'react';
import {SubmissionError} from 'redux-form';
import {useTranslation} from 'react-i18next';
import get from 'lodash/get';
import has from 'lodash/has';
import mapValues from 'lodash/mapValues';
import {AppContext, AuthContext} from '../../context';

const MIME_TYPE_APP_JSON = 'application/json';
const MIME_TYPE_APP_JSON_LD = 'application/ld+json';
const MIME_TYPE_URL_ENCODED = 'application/x-www-form-urlencoded';

const useDataAccess = () => {
    const { t } = useTranslation();
    const {
        authState,
        logout
    } = useContext(AuthContext);
    const {
        initAppState,
        showMessageAppState
    } = useContext(AppContext);

    const fetch = (id, options = {}) => {
        return new Promise((resolve, reject) => {
            if('undefined' === typeof options.headers) options.headers = new Headers();
            if(null === options.headers.get('Accept'))
                options.headers.set('Accept', MIME_TYPE_APP_JSON_LD);

            if(null !== localStorage.getItem('token'))
                options.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`);

            if(
                'undefined' !== options.body &&
                !(options.body instanceof FormData) &&
                null === options.headers.get('Content-Type')
            )
                options.headers.set('Content-Type', MIME_TYPE_APP_JSON_LD);

            if(
                'login' !== id &&
                'register' !== id  &&
                'create-password-user' !== id &&
                !id.includes('confirm-user') &&
                authState.user &&
                null !== authState.user.token
            )
                options.headers.set('Authorization', `Bearer ${authState.user.token}`);

            if('login' === id || id.includes('confirm-user')) {
                options.headers.set('Content-Type', MIME_TYPE_APP_JSON);
            }
            if('register' === id || 'create-password-user' === id || 'create_order' === id) {
                options.headers.set('Content-Type', MIME_TYPE_URL_ENCODED);
                let formBody = [];
                for(let param in options.body) {
                    let encodedKey = encodeURIComponent(param);
                    let encodedValue = encodeURIComponent(options.body[param]);
                    formBody.push(encodedKey + "=" + encodedValue);
                }
                formBody = formBody.join("&");
                options.body = formBody;
            } else if('media_objects' === id || new RegExp('import-products/\\d+').test(id)) {
                delete options.headers['Content-Type'];
            } else if('GET' !== options.method && options.body) {
                options.body = JSON.stringify(options.body);
            }

            let baseUrl = process.env.REACT_APP_API_ENTRYPOINT + 'api/';
            if(
                'login' === id ||
                'register' === id ||
                'create-password-user' === id ||
                id.includes('confirm-user')
            ) baseUrl = process.env.REACT_APP_API_ENTRYPOINT;

            try {

                resolve(global.fetch(new URL(id, baseUrl), options).then(response => {
                    if(response.ok) return response;

                    const errorUrl = response.url;
                    if(400 === response.status) {
                        showMessageAppState('error', t('bad_request'));
                        return response.json().then(json => {
                            const error = json.message;
                            if (!json.violations) throw Error(error);
                        });
                    }
                    if(401 === response.status) {
                        return response.json().then(json => {
                            if ("Expired JWT Token" === json.message || "Invalid JWT Token" === json.message) {
                                showMessageAppState('error', t('expirated_token'));
                                initAppState();
                                logout();
                            }
                            const error = "Bad credentials." === json.message ? 'bad_credentials' : json.message;
                            if (!json.violations) throw Error(error);
                        });
                    }
                    if(403 === response.status) {
                        showMessageAppState('error', t('access_denied'));
                        throw Error(errorUrl);
                    }

                    return response.json().then(json => {
                        const error = json['hydra:description'] || response.statusText;
                        if (!json.violations) throw Error({url: errorUrl, message: error});

                        let errors = {_error: error};
                        json.violations.map(
                            violation => (errors[violation.propertyPath] = violation.message)
                        );
                        throw new SubmissionError(errors);
                    });
                }));
            } catch(e) {
                reject(e);
            }
        });
    };

    const mercureSubscribe = (url, topics) => {
        topics.forEach(topic =>
            url.searchParams.append('topic', new URL(topic, process.env.REACT_APP_API_ENTRYPOINT))
        );

        return new EventSource(url.toString());
    };

    const normalize = data => {
        if(has(data, 'hydra:member')) {
            // Normalize items in collections
            data['hydra:member'] = data['hydra:member'].map(item => normalize(item));

            return data;
        }

        // Flatten nested documents
        return mapValues(data, value =>
            Array.isArray(value)
                ? value.map(v => get(v, '@id', v))
                : get(value, '@id', value)
        );
    };

    const extractHubURL = response => {
        const linkHeader = response.headers.get('Link');
        if(!linkHeader) return null;

        const matches = linkHeader.match(
            /<([^>]+)>;\s+rel=(?:mercure|"[^"]*mercure[^"]*")/
        );

        return matches && matches[1] ? new URL(matches[1], process.env.REACT_APP_API_ENTRYPOINT) : null;
    };

    return {
        fetch,
        mercureSubscribe,
        normalize,
        extractHubURL
    };
};

export default useDataAccess;
