import React, {useContext, useEffect} from 'react';
import {Redirect} from "react-router-dom";
import {useTranslation} from 'react-i18next';
import {
    Button,
    CircularProgress,
    Container,
    FormGroup,
    Grid,
    makeStyles,
    TextField,
} from '@material-ui/core';
import {AppContext, AuthContext} from '../../context';
import {useDataAccess, useForm} from '../custom_hooks';
import Logo from "../common/Logo";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItem: 'center',
        justifyContent: 'center',
    },
    form: {
        width: '90%',
        maxWidth: '480px',
        padding: theme.spacing(2),
        margin: 'auto',
    },
    formControl: {
        background: theme.palette.background.default,
        margin: theme.spacing(1),
    },
    formControlSubmit: {
        margin: theme.spacing(1),
        marginTop: theme.spacing(2),
    },
    progress: {
        color: theme.palette.primary.main,
    },
    input: {
        color: 'rgba(255, 255, 255, 0.92) !important',
    },
    underline: {
        '&:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.92) !important',
        },
        '&:after': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.92) !important',
        },
        '&:hover:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.92) !important',
        },
    },
    icon: {
        color: theme.palette.primary.main,
    },
}));

const ConfirmUser = props => {
    const {
        authState,
        initAuthReducer,
        fetchAuthInit,
        fetchConfirmUser,
        fetchAuthError
    } = useContext(AuthContext);
    const {
        showMessageAppState
    } = useContext(AppContext);
    const { fetch } = useDataAccess();
    const { t } = useTranslation();
    const classes = useStyles();
    const handleCreate = () => {
        fetchAuthInit();
        fetch(
            `/confirm-user/${inputs.confirmationToken}`,
            {
                method: 'GET'
            }
        )
            .then(() => {
                showMessageAppState('success', t('user_confirmed'));
                fetchConfirmUser();
                props.history.push('/login');
            })
            .catch(error => {
                fetchAuthError(error);
            })
    };
    const {inputs, handleInputChange, handleSubmit, setInputs} = useForm(handleCreate);

    useEffect(() => {
        initAuthReducer();
        setInputs(inputs => ({...inputs, 'confirmationToken': props.match.params.token}));
        // eslint-disable-next-line
    }, []);

    if(authState.isLoggedIn) {
        return (
            <Redirect to='/' />
        )
    } else {
        return (
            <div className={classes.root}>
                <Container className={classes.form}>
                    <Grid
                        container
                    >
                        <Grid item xs={12} className="text-center">
                            <Logo />
                        </Grid>
                        <Grid item xs={12} className="text-center">
                            <h6>{t('confirm_user')}</h6>
                        </Grid>

                        {authState.isLoading && (
                            <Grid item xs={12} className="text-center">
                                <CircularProgress className={classes.progress} />
                            </Grid>
                        )}
                        {authState.isError && (
                            <Grid item xs={12} className="text-center">
                                <div className="alert alert-danger" role="alert">
                                    <span className="fa fa-exclamation-triangle" aria-hidden="true" />{' '}
                                    {t(authState.error)}
                                </div>
                            </Grid>
                        )}

                        <Grid item xs={12} className="text-center">
                            <form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <TextField
                                        margin="normal"
                                        error={authState.isError}
                                        id="confirmationToken"
                                        name="confirmationToken"
                                        label={t('confirmation_token')}
                                        type='text'
                                        onChange={handleInputChange}
                                        value={inputs.confirmationToken ? inputs.confirmationToken : ''}
                                        className={classes.formControl}
                                        InputProps={{
                                            classes: {
                                                root: classes.input,
                                                underline: classes.underline
                                            }
                                        }}
                                        InputLabelProps={{
                                            className: classes.input
                                        }}
                                        required
                                    />
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        size="large"
                                        className={classes.formControlSubmit}
                                        disabled={authState.isLoading}
                                    >
                                        {t('confirm_user')}
                                    </Button>
                                </FormGroup>
                            </form>
                        </Grid>
                    </Grid>
                </Container>
            </div>
        )
    }
};

export default ConfirmUser;