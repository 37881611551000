import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {
    Container,
    Divider,
    Grid,
    List as MaterialList,
    ListItem,
    ListItemText,
    makeStyles,
    Typography
} from "@material-ui/core";
import {SpeedDial, SpeedDialAction, SpeedDialIcon} from "@material-ui/lab";
import {Check, Clear, Delete, Edit, LocalShipping, MoveToInbox} from "@material-ui/icons";
import {AppContext, AuthContext} from '../../context';
import {useDataAccess} from "../custom_hooks";
import {ConfirmDialog} from "../dialog";
import {parseError} from "../../utils/hydraParser";

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    address: {
        marginLeft: theme.spacing(2),
    },
    delivery: {
        marginLeft: theme.spacing(-1),
    },
    section: {
        marginLeft: theme.spacing(-1),
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        alignSelf: 'flex-end',
    },
    speedDial: {
        zIndex: '1000',
        position: 'fixed',
        bottom: theme.spacing(2),
        right: theme.spacing(2),
    },
    speedDialAction: {
        zIndex: 1000,
        color: theme.palette.text.customPrimary,
    },
}));

const Show = props => {
    const { t } = useTranslation();
    const { authState } = useContext(AuthContext);
    const {
        appState,
        fetchInitAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        addRefreshResourceAppState,
        setPageInfos,
        showMessageAppState
    } = useContext(AppContext);
    const {fetch} = useDataAccess();
    const classes = useStyles();
    const [order, setOrder] = useState({});
    const [orderStatus, setOrderStatus] = useState(t('order_created'));
    const [open, setOpen] = useState(false);
    const [openDialogDelete, setOpenDialogDelete] = useState(false);

    useEffect(() => {
        setPageInfos('order', false);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        setOrderFromCache();
        if(props.match.params.id) {
            addRefreshResourceAppState(`orders/${props.match.params.id}`);
        }
        // eslint-disable-next-line
    }, [props.match.params.id]);

    useEffect(() => {
        setOrderFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.orders]);

    useEffect(() => {
        setOrderFromCache();
        // eslint-disable-next-line
    }, [appState.cachedData.orders]);

    useEffect(() => {
        if(order.status) {
            switch(order.status) {
                case 1:
                    setOrderStatus(t('order_confirmed'));
                    break;
                case 2:
                    setOrderStatus(t('preparing_order'));
                    break;
                case 3:
                    setOrderStatus(t('order_sent'));
                    break;
                default:
                    setOrderStatus(t('order_created'));
            }
        } else {
            setOrderStatus(t('order_created'));
        }
        // eslint-disable-next-line
    }, [order]);

    const setOrderFromCache = () => {
        if(
            appState.cachedData &&
            appState.cachedData.orders &&
            appState.cachedData.orders[props.match.params.id]
        ) {
            setOrder(appState.cachedData.orders[props.match.params.id]);
        }
    };

    const handleEdit = () => {
        props.history.push(`/orders/edit/${props.match.params.id}`);
    };

    const handleChangeOrderStatus = status => {
        fetchInitAppState();
        fetch(
            `orders/${props.match.params.id}`,
            {
                method: 'PUT',
                body: {
                    status: status
                }
            }
        )
            .then(() => {
                fetchSuccessAppState();
                addRefreshResourceAppState('orders');
                showMessageAppState('success', t('order_updated'));
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleDelete = () => {
        fetchInitAppState();
        fetch(
            `orders/${props.match.params.id}`,
            {
                method: 'DELETE'
            }
        )
            .then(() => {
                fetchSuccessAppState();
                showMessageAppState('success', t('order_deleted'));
                props.history.goBack();
            })
            .catch(error => {
                fetchErrorAppState(parseError(error));
            });
    };

    const handleClick = () => {
        setOpen(prevOpen => !prevOpen);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleOpenDialogDelete = () => {
        setOpenDialogDelete(true);
    };

    const handleCloseDialogDelete = () => {
        setOpenDialogDelete(false);
    };

    return (
        <Container>
            <Grid
                container
                direction="row"
                justify="space-evenly"
                alignItems="center"
                className={classes.root}
            >
                <Grid item xs={12} className="left">
                    {
                        order.id &&
                        <div>
                            <Typography className={classes.section}>
                                {t('order_detail')} :
                            </Typography>
                            <div>
                                {
                                    order.purchaser.id === authState.user.organizationId &&
                                    <div>{`${t('seller')} : ${order.seller.name}`}</div>
                                }
                                {
                                    order.seller.id === authState.user.organizationId &&
                                    <div>{`${t('purchaser')} : ${order.purchaser.name}`}</div>
                                }
                                <div>{`${t('reference')} : ${order.reference}`}</div>
                                <div>{`${t('created_at')} : ${order.createdAt}`}</div>
                                <div>{`${t('order_status')} : ${orderStatus}`}</div>
                                <div>
                                    {t('billing_address')} :
                                    <div className={classes.address}>
                                        {order.billingAddress.line1}
                                    </div>
                                    {
                                        order.billingAddress.line2 &&
                                        <div className={classes.address}>
                                            {order.billingAddress.line2}
                                        </div>
                                    }
                                    {
                                        order.billingAddress.line3 &&
                                        <div className={classes.address}>
                                            {order.billingAddress.line3}
                                        </div>
                                    }
                                    <div className={classes.address}>
                                        {order.billingAddress.postalCode} {order.billingAddress.city}
                                    </div>
                                    {
                                        order.billingAddress.countryProvince &&
                                        <div className={classes.address}>
                                            {order.billingAddress.countryProvince}
                                        </div>
                                    }
                                    <div className={classes.address}>
                                        {order.billingAddress.country}
                                    </div>
                                </div>
                                <div>
                                    {t('shipping_address')} :
                                    <div className={classes.address}>
                                        {order.shippingAddress.line1}
                                    </div>
                                    {
                                        order.shippingAddress.line2 &&
                                        <div className={classes.address}>
                                            {order.shippingAddress.line2}
                                        </div>
                                    }
                                    {
                                        order.shippingAddress.line3 &&
                                        <div className={classes.address}>
                                            {order.shippingAddress.line3}
                                        </div>
                                    }
                                    <div className={classes.address}>
                                        {order.shippingAddress.postalCode} {order.shippingAddress.city}
                                    </div>
                                    {
                                        order.shippingAddress.countryProvince &&
                                        <div className={classes.address}>
                                            {order.shippingAddress.countryProvince}
                                        </div>
                                    }
                                    <div className={classes.address}>
                                        {order.shippingAddress.country}
                                    </div>
                                </div>
                            </div>
                            <Typography className={classes.section}>
                                {t('ordered_products')} :
                            </Typography>
                        </div>
                    }
                    <MaterialList component="nav" className={classes.root} aria-label={t('order')}>
                    {
                        order.id &&
                        order.orderItems &&
                        order.orderItems.length > 0 &&
                        order.orderItems.map(orderItem => {
                            return (
                                <div key={orderItem.id}>
                                    <ListItem>
                                        <ListItemText
                                            primary={`${t('product')} : ${orderItem.product.name}`}
                                            secondary={
                                                <span>
                                                    <span>
                                                        {`${t('unit_price')} : ${orderItem.price} €`}
                                                    </span>
                                                    <br />
                                                    <span>
                                                        {`${t('quantity')} : ${orderItem.quantity}`}
                                                    </span>
                                                    <br />
                                                    <span>
                                                        {`${t('subtotal')} : ${orderItem.quantity * orderItem.price} €`}
                                                    </span>
                                                </span>
                                            }
                                        />
                                    </ListItem>
                                    <Divider />
                                </div>
                            )
                        })
                    }
                    {
                        !appState.isLoading &&
                        !appState.isRefreshing &&
                        order.id &&
                        order.orderItems &&
                        order.orderItems.length <= 0 &&
                        <div>{t('no_result')}</div>
                    }
                    </MaterialList>
                    {
                        order.id &&
                        <div className={classes.section}>
                            <Typography>
                                <small>{`${t('delivery_type')} : ${order.deliveryType ? t('express') : t('standard')}`}</small>
                            </Typography>
                            <Typography>
                                <small>{`${t('delivery_cost')} : ${order.deliveryCost} ${t('currency')}`}</small>
                            </Typography>
                            <Typography>
                                {`${t('total_amount')} : ${order.total} ${t('currency')}`}
                            </Typography>
                        </div>
                    }
                    {
                        (
                            !order.id
                        ) &&
                        !appState.isLoading &&
                        <div>{t('no_result')}</div>
                    }
                </Grid>
            </Grid>
            {
                order.id &&
                authState.user.organizationId &&
                (
                    authState.user.roles.includes('ROLE_SUPERADMIN') ||
                    authState.user.organizationId === order.seller.id
                ) &&
                <SpeedDial
                    ariaLabel="speeddial"
                    className={classes.speedDial}
                    icon={<SpeedDialIcon />}
                    onBlur={handleClose}
                    onClick={handleClick}
                    onClose={handleClose}
                    onFocus={handleOpen}
                    onMouseEnter={handleOpen}
                    onMouseLeave={handleClose}
                    open={open}
                >
                    {
                        order.status === 1 && 
                        <SpeedDialAction
                            key='confirm'
                            title={t('cancel_confirm_order')}
                            className={classes.speedDialAction}
                            icon={<Clear />}
                            tooltipTitle={t('cancel_confirm_order')}
                            onClick={() => handleChangeOrderStatus(0)}
                        />
                    }
                    {
                        order.status !== 1 && 
                        <SpeedDialAction
                            key='confirm'
                            title={order.status !== 2 ? t('confirm_order') : t('cancel_prepare_order')}
                            className={classes.speedDialAction}
                            icon={<Check />}
                            tooltipTitle={t('confirm_order')}
                            onClick={() => handleChangeOrderStatus(1)}
                        />
                    }
                    {
                        order.status !== 2 && 
                        <SpeedDialAction
                            key='prepare'
                            title={order.status !== 3 ? t('prepare_order') : t('cancel_sent_order')}
                            className={classes.speedDialAction}
                            icon={<MoveToInbox />}
                            tooltipTitle={t('prepare_order')}
                            onClick={() => handleChangeOrderStatus(2)}
                        />
                    }
                    {
                        order.status !== 3 && 
                        <SpeedDialAction
                            key='send'
                            title={t('send_order')}
                            className={classes.speedDialAction}
                            icon={<LocalShipping />}
                            tooltipTitle={t('send_order')}
                            onClick={() => handleChangeOrderStatus(3)}
                        />
                    }
                </SpeedDial>
            }
            {
                order.id &&
                order.status === 0 &&
                authState.user.organizationId &&
                (
                    authState.user.roles.includes('ROLE_SUPERADMIN') ||
                    authState.user.organizationId === order.purchaser.id
                ) &&
                <SpeedDial
                    ariaLabel="speeddial"
                    className={classes.speedDial}
                    icon={<SpeedDialIcon />}
                    onBlur={handleClose}
                    onClick={handleClick}
                    onClose={handleClose}
                    onFocus={handleOpen}
                    onMouseEnter={handleOpen}
                    onMouseLeave={handleClose}
                    open={open}
                >
                        <SpeedDialAction
                            key='edit'
                            title={t('edit_order')}
                            className={classes.speedDialAction}
                            icon={<Edit/>}
                            tooltipTitle={t('edit_order')}
                            onClick={handleEdit}
                        />
                        <SpeedDialAction
                            key='delete'
                            title={t('delete_order')}
                            className={classes.speedDialAction}
                            icon={<Delete />}
                            tooltipTitle={t('delete_order')}
                            onClick={handleOpenDialogDelete}
                        />
                </SpeedDial>
            }
            <ConfirmDialog
                open={openDialogDelete}
                onClose={handleCloseDialogDelete}
                onConfirm={handleDelete}
                title='confirm_delete'
                content='confirm_order_delete_content'
                {...props}
            />
        </Container>
    )
};

export default Show;