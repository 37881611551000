import React from 'react';
import {Route} from 'react-router-dom';
import {Wrapper} from '../hooks/common';
import {Create, Links, List, Show, Update} from '../hooks/catalog/';
import {Create as CreateProduct, Show as ShowProduct, Update as UpdateProduct} from '../hooks/product/';

export default [
  <Route path="/catalogs/create" component={props => <Wrapper component={Create} {...props} />} exact key="create-catalog" />,
  <Route path="/catalogs/create_product/:catalog" component={props => <Wrapper component={CreateProduct} {...props} />} exact key="create-product" />,
  <Route path="/catalogs/edit/:id" component={props => <Wrapper component={Update} {...props} />} exact key="edit-catalog" />,
  <Route path="/catalogs/show/:id" component={props => <Wrapper component={Show} {...props} />} exact key="show-catalog" />,
  <Route path="/catalogs/show_product/:id" component={props => <Wrapper component={ShowProduct} {...props} />} exact key="show-product" />,
  <Route path="/catalogs/create_product/:id" component={props => <Wrapper component={CreateProduct} {...props} />} exact key="create-product" />,
  <Route path="/catalogs/edit_product/:id" component={props => <Wrapper component={UpdateProduct} {...props} />} exact key="edit-product" />,
  <Route path="/catalogs/manage_links/:id" component={props => <Wrapper component={Links} {...props} />} exact key="manage-links-catalog" />,
  <Route path="/catalogs" component={props => <Wrapper component={List} {...props} />} exact strict key="list-catalogs" />,
  <Route path="/own_catalogs" component={props => <Wrapper component={List} type='owner' {...props} />} exact strict key="list-own-catalogs" />,
  <Route path="/granted_catalogs" component={props => <Wrapper component={List} type='granted' {...props} />} exact strict key="list-granted-catalogs" />,
  <Route path="/catalogs/:page" component={props => <Wrapper component={List} {...props} />} exact strict key="list-page-catalogs" />
];