import React from 'react';
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import HomeDrawer from "./hooks/HomeDrawer";
import {createBrowserHistory} from "history";
import {Router, Switch} from 'react-router-dom';
import addressRoutes from "./routes/address";
import cartRoutes from "./routes/cart";
import catalogRoutes from "./routes/catalog";
import customdeliverycostRoutes from "./routes/customdeliverycost";
import custommarginRoutes from "./routes/custommargin";
import orderRoutes from "./routes/order";
import organizationRoutes from "./routes/organization";
import productRoutes from "./routes/product";
import userRoutes from "./routes/user";
import authRoutes from "./routes/auth";
import homeRoutes from "./routes/home";
import {AppContext, AuthContext} from './context';
import {useAppState, useAuthApi} from './hooks/custom_hooks';

export const history = createBrowserHistory();
const theme = createMuiTheme({
    typography: {
        fontFamily: [
            '-apple-system',
            '"Helvetica Neue"',
            'Roboto',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
          ].join(','),
    },
    palette: {
        primary: {
            main: 'rgba(255, 255, 255, 0.92)',
        },
        secondary: {
            main: '#fc0038' 
        },
        text: {
            primary: 'rgba(255, 255, 255, 0.92)',
            secondary: 'rgba(255, 255, 255, 0.54)',
            customPrimary: '#1d3856',
            customSecondary: '#fc0038',
        },
        background: {
            paper: '#fafafa',
            default: '#1d3856',
            customPrimary: 'rgba(255, 255, 255, 0.92)',
            customSecondary: '#fc0038',
        },
        divider: 'rgba(255, 255, 255, 0.92)',
    }
});

const App = () => {
    const {
        appState,
        initAppState,
        initCachedDataAppState,
        fetchInitAppState,
        fetchAbortAppState,
        fetchSuccessAppState,
        fetchErrorAppState,
        updateCachedDataAppState,
        addRefreshResourceAppState,
        forceRefreshResourceAppState,
        removeRefreshResourceAppState,
        setRefreshStatusAppState,
        setRefreshViewAppState,
        initMessageAppState,
        showMessageAppState,
        setPageInfos
    } = useAppState();
    const {
        authState,
        initAuthReducer,
        fetchAuthInit,
        fetchAuthError,
        fetchLogin,
        fetchRegister,
        fetchCreatePassword,
        fetchConfirmUser,
        fetchRefresh,
        logout
    } = useAuthApi();

    return (
        <AppContext.Provider value={
            {
                appState,
                initAppState,
                initCachedDataAppState,
                fetchInitAppState,
                fetchAbortAppState,
                fetchSuccessAppState,
                fetchErrorAppState,
                updateCachedDataAppState,
                addRefreshResourceAppState,
                forceRefreshResourceAppState,
                removeRefreshResourceAppState,
                setRefreshStatusAppState,
                setRefreshViewAppState,
                initMessageAppState,
                showMessageAppState,
                setPageInfos
            }
        }>
            <AuthContext.Provider value={
                {
                    authState,
                    initAuthReducer,
                    fetchAuthInit,
                    fetchAuthError,
                    fetchLogin,
                    fetchRegister,
                    fetchCreatePassword,
                    fetchConfirmUser,
                    fetchRefresh,
                    logout
                }
            }>
                <ThemeProvider theme={theme}>
                    <Router history={history}>
                        <HomeDrawer history={history}>
                            <Switch>
                                {addressRoutes}
                                {cartRoutes}
                                {catalogRoutes}
                                {customdeliverycostRoutes}
                                {custommarginRoutes}
                                {orderRoutes}
                                {organizationRoutes}
                                {productRoutes}
                                {userRoutes}
                                {authRoutes}
                                {homeRoutes}
                            </Switch>
                        </HomeDrawer>
                    </Router>
                </ThemeProvider>
            </AuthContext.Provider>
        </AppContext.Provider>
    );
};

export default App